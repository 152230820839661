.footer {
	margin-top: 2rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: $black;
	color: $white;
	label {
		color: $white;
		margin-bottom: 0.5rem;
	}
	a {
		color: $white;
		&:hover {
			color: $primary_color;
		}
	}
}