.calendar-wrap {
	@include clearfix;
}

.calendar {
	width: 80px;
	margin-bottom: 1rem;
	background-color: $true_white;
	border: 1px solid $secondary-color;
}

.calendar-month {
	padding: 2px 4px;
	background-color: $secondary-color;
	color: $true_white;
	font-size: 1.2rem;
	text-align: center;
}

.calendar-day {
	padding: 2px 4px;
	color: $secondary-color;
	font-size: 2.5rem;
	text-align: center;
}

.calendar-info {
	font-size: 0.9rem;
	padding-left: 100px;
}