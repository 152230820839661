body {
	border-top: 8px solid $primary_color;
}

.text-muted {
	color: $light-gray;
}

.section-row {
	margin-bottom: 3rem;
}

.section-header-primary {
	color: $primary-color;
	line-height: 1;
}

.section-header-secondary {
	color: $secondary-color;
	line-height: 1;
}

.section-subheader {
	@include get-header-size(h5, small);
	margin-top: $header-margin-bottom;
	color: $rich_black;
	font-weight: $global-weight-bold;
	@include breakpoint(medium) {
		@include get-header-size(h5, medium);
	}
}

.section-background {
	padding-top: $callout-padding;
	padding-bottom: $callout-padding;
	background-color: $beige;
}

.white-background {
	background-color: $white;
}

.black-background {
	background-color: $black;
}

.primary-background {
	background-color: $primary-color;
}

.white-button {
	@include button-style($white, $true_white, $black);
}

.thumbnail-round,
.media-object.stack-for-small .media-object-section img.thumbnail-round {
	border-radius: 50%;
	width: 120px;
}

p.lead::first-letter {
	color: $medium-gray;
	float: left;
	font-size: 3em;
	line-height: 1;
	margin: 0 .2em 0 0;
}

[type='submit'],
[type='button'] {
	border-radius: $button-radius;
}

.text-center-on-small-only {
	@include breakpoint(small only) {
		text-align: center;
	}
}

.text-center-on-medium {
	@include breakpoint(medium) {
		text-align: center;
	}
}

.mb-0 {
	margin-bottom: 0;
}