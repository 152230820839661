
$large-header-image-bg: url('../img/header.jpg');
$large-header-image-height: rem-calc(400);

.large-header-image {
  background: $large-header-image-bg no-repeat center;
  background-size: cover;
  height: $large-header-image-height;
  position: relative;

  @include breakpoint(small only) {
    height: ($large-header-image-height / 1.5);
  }
}