.menu-secondary {
	font-size: 0.85rem;
	@include breakpoint(medium) {
	    font-size: 1rem;
	}
	a {
		color: $secondary_color;
		&:hover,
		&:focus {
		  color: scale-color($secondary_color, $lightness: -14%);
		}
	}
}

.menu-centered-on-small-only {
	@include breakpoint(small only) {
		text-align: center;

		> .menu {
			display: inline-block;
			vertical-align: top;
		}
	}
}

.input-search {
	border: 0;
	box-shadow: none;
	text-align: right;
	font-size: 1.2rem;
	font-style: italic;
}

.title-bar-title {
	font-size: 1.2rem;
}

.main-nav {
	background-color: $black;
	a {
		color: $white;
		&:hover,
	    &:focus {
			color: scale-color($secondary_color, $lightness: 50%);
	    }
	}
	font-size: 1.2rem;
	ul ul {
		font-size: 1rem;
	}
	@include breakpoint(large) {
		a {
			color: $white;
			&:hover,
		    &:focus {
				color: $true_white;
				background-color: $rich_black;
		    }
		}
	    font-size: 2rem;
		ul ul {
			font-size: 1.5rem;
		}
	}
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}